@import './variables.scss';

@import './app/styles/font.scss';
@import './app/styles/background.scss';

$font-family-base: 'Nunito Sans';

:root {
  --body-background: #f8f8f8;
  --primary-color: #0047cf;
  --primary-btn-text-color: #ffffff;
  --primary-text-color: #000000;
  --secondary-color: #dae2ff;
  --secondary-btn-text-color: #002c71;
  --table-primary-background: #dfe6f8;
  --table-secondary-background: #dfe6f8;
  --red: #ff5449;
  --orange: #d5a326;
}

html,
body {
  height: 100vh;
  margin: 0;
  font-size: 14px;
  font-family: $font-family-base;
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  padding: 1rem;
  overflow: hidden;
}

textarea {
  resize: none;
}

// used in tables
.checkbox-cell {
  width: 1rem;
  padding-left: 0.6rem !important;
  padding-right: 0 !important;
}

.p-checkbox {
  width: 1.5rem;
  height: 1.5rem;

  .p-checkbox-box {
    border-radius: 0;
    border-color: $gray;
    width: 1.5rem;
    height: 1.5rem;

    &.p-highlight {
      background-color: $primary-color;
      border-color: $primary-color;
    }

    .p-icon {
      width: 1rem;
      height: 1rem;
    }
  }

  &:not(.p-checkbox-disabled) {
    .p-checkbox-box {
      &:hover {
        border-color: $primary-color;

        &.p-highlight {
          background-color: $primary-color;
          border-color: $primary-color;
          color: $primary-btn-text-color;
        }
      }

      &.p-focus {
        box-shadow: none;
        border-color: $primary-color;
      }
    }
  }
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  text-decoration: none;
}

.tab-container {
  .p-tabview {
    .p-tabview-nav {
      border-color: transparent;
      gap: 16px;
      background: none;

      li {
        .p-tabview-nav-link {
          border-color: transparent;
          color: $gray;
          background: none;
          font-size: 1.25rem;
          padding: 0 0 5px 0;

          &:not(.p-disabled):focus {
            box-shadow: none;
          }
        }

        &.p-highlight .p-tabview-nav-link {
          border-color: $primary-color;
          color: $primary-color;
        }
      }
    }

    .p-tabview-panels {
      background: none;
    }
  }
}

// TODO: should be removed as this is not working in new update function of primeng
.p-button-outlined.custom-button-text,
.p-button-outlined.custom-button-text:hover {
  border: 0 !important;
}

.p-inputtext {
  border-color: $gray;
  &:enabled {
    &:focus {
      box-shadow: none;
      border-color: $primary-color;
    }

    &:hover {
      box-shadow: none;
      border-color: $primary-color;
    }
  }

  &.ng-dirty.ng-invalid {
    border-color: $error-red !important;
    box-shadow: none !important;
  }
}

.p-button {
  background-color: $primary-color;
  border-color: $primary-color;
  color: $primary-btn-text-color;

  &-label {
    font-weight: 500;
  }

  &-icon-left {
    font-weight: 700;
  }

  &:enabled:hover {
    background-color: $secondary-color;
    color: $secondary-btn-text-color;
    border-color: $secondary-color;
  }

  &.p-button-outlined {
    color: $primary-color;
    background-color: $white;
    &:enabled:hover {
      color: $primary-color;
    }
  }

  &.p-button-text {
    color: $primary-color;
    background-color: transparent;
    &-option {
      color: $black;
      border: none;
      text-align: left;
      &:enabled:hover {
        color: $black;
        border: none;
        background-color: $light-gray;
      }
    }

    &:enabled:hover {
      background-color: $secondary-color;
      color: $secondary-btn-text-color;
    }
  }

  &:focus {
    box-shadow: none;
  }

  &.icon-button {
    border: none !important;
    background-color: none;
    i,
    span {
      font-size: 1.5rem !important;
    }
  }
}

.download-options {
  color: black;
  max-width: 24rem;
  font-size: 1rem;
}

.action-icon {
  &:hover {
    cursor: pointer;
  }
}

.chevron-icon {
  color: $gray;
  font-weight: 700;
}

.edit-icon {
  color: $primary-color;
}

.delete-icon {
  color: $red !important;

  &-button {
    background-color: $red !important;

    span {
      color: $white;
    }
  }
}

.table-action {
  width: 2.5rem;
  padding: 0;
  font-size: 1.5rem;
  background: none;
  border: 0;
}

.header {
  font-size: 1.125rem;
  font-weight: 700;
  color: $primary-text-color;
}

.p-card {
  color: $primary-text-color;
  .p-card-body {
    padding: 0;
  }
  .p-card-content {
    padding: 1.25rem;
  }
}

.p-dialog {
  .p-dialog-header {
    .p-dialog-title {
      color: $primary-text-color;
    }
    padding-bottom: 0;

    .p-dialog-header-icon:focus {
      box-shadow: none;
    }
  }

  .p-dialog-content {
    color: $primary-text-color;
  }
}

.p-column-filter-overlay {
  width: 20.5rem;
}

.p-overlaypanel {
  .p-overlaypanel-content {
    padding: 0;
  }
  &::after,
  &::before {
    border: none;
    left: 0;
  }
}

p-overlayPanel {
  display: none;
}

.p-datatable {
  &.p-datatable-sm {
    .p-datatable-header {
      padding: 0;
    }
    .p-datatable-tbody > tr > td {
      color: $primary-text-color;
    }
  }

  &.p-datatable-hoverable-rows {
    .p-datatable-tbody > tr:not(.p-highlight):hover {
      background: $table-primary-background;
    }
  }

  .p-datatable-header {
    background-color: $table-primary-background;
  }

  .p-datatable-thead > tr > th {
    background-color: $table-primary-background;
    color: $primary-text-color;
  }

  .p-sortable-column {
    &.p-highlight {
      color: $primary-color;

      .p-sortable-column-icon {
        color: $primary-color;
      }
    }

    .p-icon-wrapper {
      display: inline-flex !important;
    }
  }
}
.hidden {
  display: none;
}

a:visited {
  text-decoration: none;
  color: $primary-color;
}

.p-toast {
  width: 40rem !important;
  .p-toast-message {
    &.p-toast-message-success {
      background: #c8f6ca;
      border: none;
      color: #005312;

      .p-toast-message-icon,
      .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
        color: #005312;
      }
    }
    .p-toast-message-content {
      padding: 0.5rem;
    }
  }
}

a {
  text-decoration: underline;
  color: $primary-color;
  &:hover {
    cursor: pointer;
  }
}

.p-progressbar .p-progressbar-value {
  background: $primary-color;
}

// for close button - refactor
.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: #005312;
}

.expand-span {
  font-size: 0.875rem;
  color: $primary-color;
  &:hover {
    cursor: pointer;
  }
}

.selected-plantations-length {
  color: $gray;
  font-size: 0.875;
}

.p-paginator {
  justify-content: end !important;

  .p-paginator-pages .p-paginator-page.p-highlight {
    background: $table-primary-background;
    border-color: $table-primary-background;
    color: $secondary-btn-text-color;
  }
}

.back-button {
  cursor: pointer;
}

.mb-2-5 {
  margin-bottom: 0.75rem;
}

.mb-8-5 {
  margin-bottom: 5.45rem;
}

.pt-2-5 {
  padding-top: 0.75rem;
}

.pl-2-5 {
  padding-left: 0.75rem;
}

.ml-5-5 {
  margin-left: 2.5rem;
}

.p-progressbar {
  height: 6px !important;
  border-radius: 6px 6px 0 0;
}

.p-datatable-loading-icon {
  display: none;
}

.p-link:focus {
  box-shadow: none;
}

.p-dropdown {
  border: 1px solid $gray;
  &:not(.p-disabled) {
    &.p-focus {
      box-shadow: none;
      border-color: $primary-color;
    }
    &:hover {
      border-color: $primary-color;
    }
  }
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: $secondary-btn-text-color;
  background-color: $table-primary-background;
}

.fill-icon-primary {
  color: $primary-color;
}

.modal-action-section {
  border-top: 2px solid $light-gray;
  display: flex;
  gap: 1rem;
  padding: 1.5rem;
  justify-content: end;
  margin-top: 1.5rem;
}

.p-datepicker table td > span.p-highlight {
  color: $secondary-btn-text-color;
  background-color: $table-primary-background;
}

.danger-button {
  background-color: $red;
  border-color: $red;
}

// used in map page for toggling selected plantation details section
.toggle-plantation-active {
  background-color: $primary-color;

  .btn {
    color: $white;
  }
}

.toggle-plantation-inactive {
  background-color: $white;

  .btn {
    color: $dark-gray;
  }
}

// for material UI icons
.fill-0 {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;
}

// for risk rating column
.risk-rating {
  border-radius: 5px;
}

.compliant {
  background-color: $light-green;
  color: $green;
}

.non-compliant {
  background-color: $light-red;
  color: $red;
}

.p-tag {
  background-color: $primary-color;
}

.partial-data {
  background-color: $light-orange;
  color: $orange;
}

.p-chips {
  .p-chips-multiple-container {
    &:not(.p-disabled):hover {
      border-color: $primary-color;
    }

    &:not(.p-disabled).p-focus {
      box-shadow: none;
    }

    .p-chips-token {
      background-color: $secondary-color;
      color: $secondary-btn-text-color;
    }
  }
}

.confirmation-dialog {
  .p-dialog-header {
    padding: 1.5rem !important;
  }
}

// styles for table filters - immediately show options and adjust
.p-column-filter-overlay-menu {
  .p-column-filter-constraint {
    padding: 0;
  }

  .p-column-filter-buttonbar {
    padding: 0;
  }
}

.text-overlay {
  background: $tooltip-bg;
  color: $toolip-text;
}

.selected-chips-container {
  max-height: 10rem;
  overflow: auto;
  .p-chips .p-chips-multiple-container {
    border: none;
    padding: 0.375rem 0.75rem 0.375rem 0;
    cursor: default;
    caret-color: transparent;
    max-height: 10rem;
    overflow: auto;
    .p-chips-token {
      background-color: $secondary-color;
      color: $secondary-btn-text-color;
    }

    &:not(.p-disabled).p-focus {
      box-shadow: none;
    }
  }
}

.p-fluid {
  .p-multiselect {
    height: 0;
  }
}

.p-multiselect {
  border: none;

  .p-multiselect-trigger {
    display: none;
  }
}

.text-red {
  color: $red;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: $primary-color;
}

@media only screen and (max-width: 1439px) {
  html,
  body {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1023px) {
  html,
  body {
    font-size: 10px;
  }
}
