$bg-colors: (
  none: none,
  transparent: transparent
);

// Loop through the map and create a class for each
@each $name, $value in $bg-colors {
  .bg-#{$name} {
    background: $value !important;
  }
}