// Define a map of font sizes
$font-sizes: (
  12px: 12px,
  14px: 14px,
  16px: 16px,
  18px: 1.25rem,
  20px: 20px
);

// Loop through the map and create a class for each font size
@each $name, $size in $font-sizes {
  .text-#{$name} {
    font-size: $size;
  }
}


// Define a map of font sizes
$text-colors: (
  'primary': var(--primary-color)
);

// Loop through the map and create a class for each font size
@each $name, $color in $text-colors { 
  .text-#{$name} {
    color: $color;
  }
}
