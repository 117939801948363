$body-background: var(--body-background);
$header-background: var(--header-background);
$primary-color: var(--primary-color);
$primary-btn-text-color: var(--primary-btn-text-color);
$primary-text-color: var(--primary-text-color);
$secondary-color: var(--secondary-color);
$secondary-btn-text-color: var(--secondary-btn-text-color);
$table-primary-background: var(--table-primary-background);
$table-secondary-background: var(--table-secondary-background);
$tooltip-bg: var(--tooltip-bg);
$tooltip-text: var(--tooltip-text);
$red: var(--red);
$orange: var(--orange);
$white: var(--white);
$gray: var(--gray);
$light-red: var(--light-red);
$error-red: var(--error-red);
$light-green: var(--light-green);
$green: var(--green);
$light-gray: var(--light-gray);
$dark-gray: var(--dark-gray);
$light-orange: var(--light-orange);
$black: var(--black);
