$body-background: var(--body-background);
$primary-color: var(--primary-color);
$primary-btn-text-color: var(--primary-btn-text-color);
$primary-text-color: var(--primary-text-color);
$secondary-color: var(--secondary-color);
$secondary-btn-text-color: var(--secondary-btn-text-color);
$table-primary-background: var(--table-primary-background);
$table-secondary-background: var(--table-secondary-background);
$red: var(--red);
$orange:var(--orange);

$white: #fff;
$gray: #5d5e67;
$light-red: #ffedea;
$error-red: #e24c4c;
$light-green: #c9ffbf;
$green: #3aa53f;
$light-gray: #c6c6d0;
$dark-gray: #333333;
$light-orange: #fef0cd;
$black: #000;

$tooltip-bg: #494F58;
$toolip-text: #fff;
